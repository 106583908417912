/*Search*/

.header-search {
    margin-bottom: 2em;
    z-index: 20;

    @include media-breakpoint-up(lg) {
        position: absolute;
        right: 23px;
        display: none;
        margin-bottom: 0;
    }

    .form-group {
        margin-bottom: 0;
        margin-top: 0;
        width: auto !important;
    }
}

.search-expander {
    cursor: pointer;

    .close-icon {
        display: none;
    }
}

.search {
    input {
        border-radius: 5px 0 0 5px;

        @include media-breakpoint-up(lg) {
            width: 300px !important;
        }
    }

    .btn {
        padding: 0 13px;
        margin-top: 0;
        border-radius: 0 5px 5px 0;
        box-shadow: none;
    }

    span {
        color: #aa7a1a;
        padding-right: 0;
    }

    svg {
        width: 21px;
        height: 21px;

        &.open-icon {
            width: 30px;
            height: 30px;
            padding: 5px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
}

//Search results dropdown header
.ui-menu.sf-autocomplete {
    background: white;
    padding: 18px;
    list-style: none;
    margin-top: 3px;
    z-index: 10000;

    li {
        border-bottom: 1px solid #ccc;
        padding: 5px;
        list-style: none;
    }
}

//Search Results main page
.searchResults {
    .SearchTitle {
        margin: 3rem 0 4rem 0;

        h3 {
            font-weight: 700;
        }
    }
}

#SearchWrapper {
    margin: 3rem 0 4rem 0;

    @include media-breakpoint-up(lg) {
        margin: 5rem 0;
    }

    .media-body {
        margin-bottom: 3.5rem;
    }
}
