/*Navigation Primary*/
.master-nav {
    font-size: 0.8rem;
    @include box-shadow(0px, 2px, 6px, 0, rgba(0, 0, 0, 0.6));
    height: 56px;
    padding: 0 1rem;
    @media (min-width: 1300px) {
      font-size: 0.9rem;
    }
    .container-fluid {
        position: relative;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
}

//Mobile Chevron icon
#chevron {
    display: none;
}

//Mobile Toggle button
.navbar-toggler {
    top: 5px;
    right: 0;
    width: 42px;
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 5px;
        width: 85%;
        background: $primary;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 13px;
        }

        &:nth-child(4) {
            top: 26px;
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}

//Navigation
#master-nav {
    width: 75%;

    @media (min-width: 1500px) {
        margin: auto;
    }

    @include media-breakpoint-up(xl) {
        position: relative;
        padding-right: 10%;
    }

    @media screen and (max-width: 1499px) and (min-width: 1200px) {
        margin-left: 16%;
    }

    @include media-breakpoint-up(md) {
        width: inherit;
    }


    //Don't remove this. This needs to be hidden on Desktop. Its the mobile chevron
    #chevron {
        @include media-breakpoint-up(lg) {
            display: none !important;
        }
    }

    li {
        &.active {
            > .nav-link {
                font-weight: bold;
            }
        }
    }

    > li {
        //Specific to first UL links
        &.active, &:hover {
            > .nav-link {
                position: relative;
                text-decoration: underline;
                -webkit-text-underline-position: under;
                -ms-text-underline-position: below;
                text-underline-position: under;

                @include media-breakpoint-up(lg) {
                    color: $primary;
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }

        > .nav-link {
            color: $black;
        }

        &.active {

            &.has-submenu {
                > .nav-link {
                    @include media-breakpoint-up(lg) {
                        &:after {
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 10px solid #333;
                            content: "";
                            display: block;
                            left: 40%;
                            position: absolute;
                            margin-left: -20px;
                            width: 0;
                            height: 0;
                            bottom: -5px;
                        }
                    }

                    @include media-breakpoint-up(xl) {
                        &:after {
                            bottom: -1px;
                        }
                    }
                }
            }

            ul.second-ul {
                display: block;

                @include media-breakpoint-up(lg) {


                    a {
                        &:after {
                            visibility: hidden;
                        }
                    }
                }

                @media screen and (max-width: 1400px) and (min-width: 1100px) {
                    li {
                        .nav-link {
                            font-size: 0.7rem;
                        }
                    }
                }
            }

            ul.second-ul,
            ul.third-ul {
                @include media-breakpoint-up(lg) {
                    font-weight: normal;
                }
            }
        }
        //end of .active
        .nav-link {
            padding-left: 1rem;
            letter-spacing: 1px;
            word-spacing: 1px;
            color: #000;

            @include media-breakpoint-up(lg) {
                padding: 0;
                padding-right: 0.9rem;
                line-height: 51px;
                font-size: 0.9em;
            }

            @media (min-width: 1362px) {
                padding-right: 1rem;
            }

            @media (min-width: 1460px) {
                padding-right: 2rem;
            }
        }

        svg {
            fill: $white;
            width: 15px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 10px;

            &.expand {
                transform: rotate(90deg);
            }
        }
    }
}

ul.second-ul {
    width: 100%;
    padding: 0;
    display: none;

    @include media-breakpoint-up(lg) {
        list-style: none;
        @include pos-absolute(108%, 0, 0, 16px);
        font-size: 0.89em;

        &:before {
            width: 1000vw;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            background-color: $silver-darkest;
            height: 52px;
            content: "";
            @include pos-absolute(-3px, 0, 0, 0);
        }
    }

    @media (min-width: 1201px) {
        font-size: .9rem;
    }

    @include media-breakpoint-up(xl) {
        @include pos-absolute(108%, 0, 0, 0);
        width: 170%;
    }

    li {
        background: $silver-darkest;
        border-top: 1px solid $black;
        border-bottom: 0;

        @include media-breakpoint-up(lg) {
            float: left;
            background: transparent;
            border-top: 0;
            z-index: 2;
            position: relative;
            line-height: 32px;

            &:hover {
                > ul.third-ul {
                    visibility: visible;
                    transition-delay: 0s;
                }

                > a {
                    color: $primary;
                    text-decoration: none;
                }
            }
        }

        .nav-link {
            font-weight: normal;
            padding-left: 1.5rem;

            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
                padding-left: 0;
                color: #fff !important;
                line-height: 49px !important;
            }
        }
    }

    .has-submenu {

        @include media-breakpoint-up(lg) {
            > a.nav-link {
                &:after {
                    border-style: solid;
                    border-width: 0.12em 0.12em 0 0;
                    content: '';
                    display: inline-block;
                    height: 0.45em;
                    left: 6px;
                    position: relative;
                    top: 20px;
                    vertical-align: top;
                    width: 0.45em;
                    transform: rotate(135deg);
                    visibility: visible !important;
                    font-weight: normal;
                }
            }
        }
    }
}

ul.third-ul {
    padding-left: 0;
    list-style: none;


    @include media-breakpoint-up(lg) {
        position: absolute;
        min-width: 510px;
        visibility: hidden;
    }

    li {
        background-color: $silver-darker;
        border-bottom: 1px solid #666;

        @include media-breakpoint-up(lg) {
            line-height: 0;
            padding: 0 1em;
            width: 100%;
        }

        &:hover {
            background-color: #666;

         }

        .nav-link {
            padding-left: 2rem;
            line-height: 35px !important;

            &:hover {
                text-decoration: none;
            }

            @include media-breakpoint-up(lg) {
                padding-right: 0 !important;
            }
        }
    }
}
//Specific to smaller screens
@media (max-width: 1100px) {
    #main-navbar {
        transform: translateX(-100%);
        position: fixed;
        top: 8px;
        bottom: 0;
        left: 0;
        margin: auto;
        transition: all ease 0.25s;
        background: $silver-light !important;
        width: 100%;
        font-size: 0.9em;

        .nav-link {
            height: 45px;
            display: flex;
            align-items: center;
        }

        &.open {
            transform: translateX(0%);
        }

        ul#master-nav {
            overflow-y: auto;
            width: 100%;

            > li {
                background: $white;
                border-bottom: 1px solid $silver;
                position: relative;
            }

            li {
                &.active > .nav-link {
                    color: $gold;
                    text-decoration: none;                   
                }
            }
        }

        ul.second-ul {
            > li {
                background: #f6f6f4;
                position: relative;
                border-top: none;
                border-bottom: 0;
            }
        }

        ul.third-ul {
            display: none;

            > li {
                background: #eee;
                padding-left: 11px;
                border-top: none;
                border-bottom: 0;
            }

            li {
                &.active > .nav-link {
                    border-bottom: none !important;
                }
            }
        }

        svg#chevron {
            fill: $black;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 26px;
            top: 10px;
            transform: rotate(90deg);
        }

        .nav-item {
            &.active {
                > svg#chevron {
                    transform: rotate(-90deg);
                }



                > ul.third-ul {
                    display: block;
                }
            }
        }
    }

    .socialmedia-mobile {
        display: flex;
        justify-content: center;
        margin-top: 2em;

        a {
            margin: 0 0.5em 0 1em;
        }
    }

    .overlay {
        @include pos-fixed();
        margin: auto;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;

        &.open {
            display: block;
            z-index: 1029; 
        }
    }
}

/* Forex login buttons */
.CTAbox {
    padding: 10px;


    a {
        padding: 10px;
        @include border-radius(10px);
        background: $blue;
        @include box-shadow(0px, 2px, 6px, 0, rgba(0, 0, 0, 0.6));
        position: relative;
        top: 0;
        transition: border-bottom 0.2s, box-shadow 0.3s, top 0.3s;
        min-height: 145px;
      
        .CTAbtn{
            background: #fff;
            color: $gold;
            text-transform: initial;
            margin-bottom: 10px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-weight: 900;
            border: 1px solid $gold;
            transition: all 0.3s ease;
        }

        .CTAdesc{
            font-size: 13px;
            font-weight: 100;           
        }

        &:hover{
            box-shadow: none;
            .CTAbtn{
                background: $gold;
                color: #fff;
            }
        }
    }
}

// Forex fees and transfers

.feesAndDestinations {
    margin-bottom: 40px;


    a {
        display: block;
        border-bottom: 1px solid #000;
        padding-bottom: 7px;
        padding-top: 7px;
        line-height: 32px;
        color: $gold;
        font-weight: 700;

        &:hover {
            color: #684a10;
        }

        &:after {
            width: 32px;
            height: 32px;
            display: block;
            background-image: url(/ResourcePackages/SableAustraliaMasterCore/assets/images/flags32.png);
            background-repeat: no-repeat;
            content: "";
            float: right;
        }

        &#AU:after {
            background-position: 0 -200px;
        }

        &#SA:after {
            background-position: 0 0;
        }

        &#NZ:after {
            background-position: -150px -50px;
        }

        &#EU:after {
            background-position: 0 -150px;
        }

        &#UK:after {
            background-position: -250px -100px;
        }

        &#CA:after {
            background-position: -200px -150px;
        }

        &#PL:after {
            background-position: -100px -50px;
        }

        &#USA:after {
            background-position: -50px 0;
        }
    }

}


//Targeting larger mobile devices,  the menu font size is too small on them
@media (min-width: 370px) and (max-width: 450px) {
    #main-navbar {
        font-size: 1.05em;
    }

    #master-nav > li .nav-link {
        padding-left: 2rem;
    }
}


//IE
@media screen and (-ms-high-contrast: none) {
    #master-nav > li.active > .nav-link, #master-nav > li:hover > .nav-link {
        font-weight: 100 !important;
    }
}
