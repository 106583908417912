/*Page Sections or Dividers*/

//CTA
.cta-section {
    p {
        margin-top: 0;
        font-size: 1.4rem;
        font-variation-settings: "wght" 450;
    }

    .btn {
        margin-top: 24px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    .row {
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    &.collapsed {

        h2, p {
            margin-bottom: 24px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 32px;
            }
        }

        .row {
            text-align: center;
        }
    }
}
//Image and content divider
.contentImage-section {

    img {
        -webkit-border-right-radius: 10px;
        -moz-borer-radius: 10px;
        border-radius: 10px;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            margin-right: 1em;
            margin-left: auto;
        }
    }




    .text {
        @include media-breakpoint-up(lg) {
            max-width: 650px;
        }
    }

    &.reorder-mobile {
        @include media-breakpoint-down(lg) {
            .text {
                order: 1;
            }
        }
    }

    .background-spacing {
        @include media-breakpoint-down(lg) {
            padding: 2em;
        }
    }

    &.right-image {
        .text {
            @include media-breakpoint-up(lg) {
                margin-left: auto;
            }
        }

        img {
            margin-right: 0;
            margin-left: 1em;
        }

        .narrow-image {

            img {
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    margin: 0 auto;
                    margin-top: 2em;
                }
            }
        }

        &.narrow {
            .container {
                @include media-breakpoint-up(xl) {
                    max-width: 1287px;
                }
            }

            .text {
                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                }
            }
        }
    }

    &.dual-banner {
        .bg-red {
            background: #bf2026 !important;
        }

        img {
            margin: 0;
            border-radius: 0;
        }
    }

    .image-left {
        div {
            @include media-breakpoint-up(md) {
                position: absolute;
                right: 0;
            }
        }

        .sf-Long-text {
            @include media-breakpoint-up(md) {
                position: relative;
            }
        }
    }

    .image-right {
        div {
            @include media-breakpoint-up(md) {
                position: absolute;
                left: 0;
            }
        }
         
        .sf-Long-text {
            @include media-breakpoint-up(md) {
                position: relative;
            }
        }
    }
}
//About Us
.about-section {
    .image {
        @include media-breakpoint-up(lg) {
            height: 105px;
            width: 310px;
            margin: 0 auto;
        }

          svg{
            @include media-breakpoint-up(lg) {
                height: 105px;
                width: 310px;
            }
          }
    }
}

svg.sable-logo-about {
    overflow: hidden;
    fill: inherit !important;
    color: inherit !important;
    width: 268px;
    height: inherit !important;
}


//Disclaimer or Regulatory Information
.disclaimer-section {
    border-top: 2px solid $silver;
    background: $silver-lighter;
    font-size: 0.8rem;
    padding: 50px 0 !important;

    p, h3 {
        margin-top: 0;
    }
}
//package or accomodation
.contact-information {

    .contact {
        margin-top: 32px;

        svg {
            width: 30px;
            height: 30px;
        }

        a {
            color: $blue;
            margin-left: 0.5em;
            margin-right: 2em;
            font-size: 1.1em;
            font-weight: bold;
        }
    }

    .details {
        float: left;
    }
}
//Section Shadow
.section-shadow {
    position: relative;
    background: #fff;
    min-height: 30px;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        -webkit-box-shadow: 0 0 20px rgba(0,0,0,.3);
        box-shadow: 0 0 13px rgba(0,0,0,.6);
        bottom: 0;
        left: 5%;
        right: 5%;
        width: 90%;
        height: 17px;
        -moz-border-radius: 100%;
        border-radius: 100%;
    }
}

//British citizenship assessment Banner
.citizenship-assessment-section {

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;

        &.left-flag {
            left: 0;
        }

        &.right-flag {
            right: 0;
        }

        @media only screen and (max-width: 450px) {
            display: none;
        }
    }
}

//Accounting Promo Banner
.acc-promo-banner {

    img {
        display: none;

        @media (min-width:1066px) {
            display: block;
            width: 100%;
        }
    }

    .text {
        @media (max-width:1065px) {
            border: 10px solid#7fa0b0;
            background: #e5e6e7;
            text-align: center;
            padding: 2em;
        }

        @media (min-width:1066px) {
            position: absolute;
            top: 8%;
            left: 50%;
            width: 24%;
        }

        @media (min-width:1640px) {
            position: absolute;
            top: 9%;
            left: 50%;
            width: 18%;
        }

        h2 {
            font-size: 2.5em;

            @media (min-width:1066px) {
                line-height: 50px;
            }



            @media (min-width:1640px) {
                font-size: 5em;
                line-height: 83px;
            }
        }

        p {
            margin-top: 10px;

            @include media-breakpoint-up(xl) {
                font-size: 1.1em;
            }

            strong {
                color: $blue;
                margin-top: 10px;
            }
        }
    }
}

//Members static
.members-static {
    justify-content: space-around;

    @include media-breakpoint-up(lg) {
        max-width: 1307px;
        margin: 0 auto;
    }

    .members-title {
        line-height: 1em;
        padding-left: 1em;
        margin-top: 0;

        @include media-breakpoint-down(sm) {
            font-size: 1.1rem;
        }
    }

    img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        @include border-radius(0.3em);
    }
}

//blue banner section
section {
    &.bg-blue, &.bg-blue-darkest {
        .content-narrow, .content-wide {
            color: #fff;

            svg {
                color: #fff;
                fill: #fff;
            }
        }
    }
}


//OISC logo on immigration templates (Lylo's hide image mobile has a bug hence this so long)
.OISC {
    max-width: 300px;

    @include media-breakpoint-up(md) {
        right: 0;
        margin-top: -40px;
    }
}

.roadshow-banner {

    .blue-background {
        background: #043246;
        color: white;
        padding-bottom: 50px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 10px;
        }

        .row {
            max-width: 1254px;
            margin: 0 auto;
        }
    }

    .roadshow-services ul {
        padding-inline-start: 19px;
    }

    .spiral-roadshow-bg {


        @include media-breakpoint-up(lg) {
            display: block;
            background: url(/images/default-source/citizenship/spiral_circle.png) no-repeat center center;
            background-size: contain;
            height: 470px;
            width: 470px;
            max-width: 200vw;
            position: absolute;
            top: -45px;
        }


        &.right {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
                right: -124px;
            }
        }
    }

    .roadshow-info {
        h4 {
            font-weight: lighter;
        }
    }

    .roadshow-info, .roadshow-services {
        padding-top: 50px;
    }


    .roadshow-schedule {
        padding: 1em 0;


        @include media-breakpoint-down(sm) {
            margin-top: 2rem;
            padding-left: 7px;
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 1em solid #043246;
        }

        @include media-breakpoint-up(md) {
            justify-content: center;
        }

        .roadshow-area {
            letter-spacing: 1px;
            color: #000;
            padding: 1em 0;
            padding-right: 1.3rem;



            @include media-breakpoint-up(md) {
                border-right: 1px solid $primary;
                justify-content: center;
                padding: 0 1rem;
            }

            &:last-child {
                border-right: none;
            }
        }

        .roadshow-date {
            color: $primary;
            font-weight: bold;
            text-align: center;
        }

        > a:last-child {
            .roadshow-area {
                border-right: none;
            }
        }
    }
}

// Bank info on UK Relocation Page
.contact{ 
    margin-top: 20px;
    .contact svg{
        height: 30px;
        width: 30px;
        float: left;
        fill: $blue; 
    }
    a{
        color: $blue;
        margin-left: .5em;
        margin-right: 2em;
        font-size: 1.1em;
        font-weight: bold;
    }
}

