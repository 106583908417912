/*Footer*/

footer {
    width: 100%;
    color: $white;
    font-size: 0.875em;

    p {
        margin: 0;
    }

    .heading {
        border-bottom: 2px solid $primary;
        margin-bottom: 1em;

        h5 {
            letter-spacing: 1px;
            line-height: 2em;
            margin-top: 0;
        }
    }

    a, a:hover {
        color: $white;
    }

    .top {

        .row {
            > div {
                @include media-breakpoint-down(sm) {
                    margin-top: 24px;
                }

                &:first-child {
                    @include media-breakpoint-down(sm) {
                        margin-top: 0;
                    }
                }
            }
        }

        .accordion {
            a {
                text-decoration: underline;
            }
        }

        ul {
            list-style: none;
            line-height: 2em;
            padding: 0;
            margin: 0;

            li {

                @include media-breakpoint-up(lg) {
                    display: inline-block;
                    width: calc(50% - 3px);
                }

                a {
                    padding-bottom: 1.3em;
                    display: block;

                    @include media-breakpoint-up(lg) {
                        padding: 0;
                        display: inline-block;
                    }
                }
            }
        }
    }

    .social {

        svg {
            fill: $white;
            width: 23px;
            height: 23px;
            margin-right: 4px;
        }

        a {
            &:hover {
                text-decoration: none;

                svg {
                    fill: $silver;
                }
            }
        }

        #youtube {
            margin-top: 5px;
        }
    }

    .bottom {
        p {
            font-size: 0.69rem;
            line-height: 1.6rem;
            font-weight: 200;
        }


        .siteinfo-links {
            margin: 0;
            padding: 0;
            padding-bottom: 1.5rem;

            @include media-breakpoint-up(md) {
                padding: 0;
            }

            li {
                list-style: none;

                @include media-breakpoint-up(lg) {
                    text-align: right;
                    display: inline;
                }

                a {
                    padding-bottom: 1.3em;
                    display: block;

                    @include media-breakpoint-up(lg) {
                        padding: 0 3px 0 3px;
                        display: inline;
                    }
                }

                &:before {
                    @include media-breakpoint-up(lg) {
                        content: '\007C';
                    }
                }

                &:first-child {
                    &:before {
                        @include media-breakpoint-up(lg) {
                            content: '';
                        }
                    }

                    a {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .col-md-4{
        svg{
            width: 24px;
            fill: #fff;
        }
    }
}
