/*Flags*/

.flag-box {
    width: 32px;
    height: 32px;
    background-image: url('/ResourcePackages/SableAustraliaMasterCore/assets/images/flags32.png');
    display: inline-block;
    //Great Britain
    &.GBP {
        background-position: -250px -100px;
    }
    //Australia
    &.AU, &.AUD {
        background-position: 0 -200px;
    }
    //New Zealand
    &.NZD {
        background-position: -150px -50px;
    }
    //Poland
    &.PLN {
        background-position: -100px -50px;
    }
    //USA
    &.USD {
        background-position: -50px 0;
    }
    //Canada
    &.CAD {
        background-position: -200px -150px;
    }
    //Thailand
    &.THB {
        background-position: -150px 0;
    }
    //Hong Kong
    &.HKD {
        background-position: -150px -100px;
    }
    //Europe
    &.EUR {
        background-position: 0 -150px;
    }
    //Congo
    &.CD {
        background-position: 300px -250px;
    }
    //Malawi
    &.MW {
        background-position: 250px -250px;
    }
    //Nigeria
    &.NG {
        background-position: 200px -250px;
    }
    //Swaziland
    &.SZ {
        background-position: 150px -250px;
    }
    //Uganda
    &.UG {
        background-position: 100px -250px;
    }
    //Zimbabwe
    &.ZW {
        background-position: 50px -250px;
    }
    //Flags Large
    &.large {
        width: 64px;
        height: 64px;
        background-image: url('/ResourcePackages/SableMasterCore/assets/src/images/flags64.png');
        //background-image: url('../../../../images/flags64.png');


        //Great Britain
        &.GBP {
            background-position: -500px -200px;
        }
        //Australia
        &.AU, &.AUD {
            background-position: 0 -400px;
        }
        //New Zealand
        &.NZD {
            background-position: -300px -100px;
        }
        //Poland
        &.PLN {
            background-position: -200px -100px;
        }
        //Canada
        &.CAD {
            background-position: -400px -300px;
        }
        //Thailand
        &.THB {
            background-position: -300px 0;
        }
        //USA
        &.USD {
            background-position: -100px 0;
        }
        //Hong Kong
        &.HKD {
            background-position: -300px -200px;
        }
        //Europe
        &.EUR {
            background-position: 0 -300px;
        }
    }
}

