﻿/*Body layout*/

//margin top spacing for headings, features, accordions etc
.pricing-cards, .features-services > div, iframe, .how-it-works, .how-it-works-simple {
    margin-top: 40px;

    @include media-breakpoint-up(lg) {
        margin-top: 70px;
    }
}

.body-placeholder ul, .body-placeholder ol {
    li {
        margin-top: 20px;
    }
}


//margin top spacing paragraphs, buttons, ul, tables
h4, h5, h6, p, .nav-cards > div, .tab-content h2, .body-placeholder ul.check-list, .body-placeholder ul.hyphen-list, .body-placeholder ul.cross-list, table,
.features-document, .features-list, .features-category > div, form, .accordion, figure, .whatpassport-section #white-label {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 32px;
    }
}

//margin top spacing h3
h3 {
    margin-top: 44px;

    @include media-breakpoint-up(lg) {
        margin-top: 52px;
    }
}

//button
.btn {
    margin-top: 40px;

    @include media-breakpoint-up(lg) {
        margin-top: 48px;
    }
}

iframe {
    border-radius: 10px;
}


//Banner spacing
.banner-placeholder {
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#156b95', endColorstr='#115578',GradientType=0 );
    background: -webkit-linear-gradient(bottom,#156B95, $blue);
    background: -moz-linear-gradient(bottom, #156B95, $blue);
    background: -o-linear-gradient(bottom, #156B95, $blue);
    background: linear-gradient(to bottom, #156B95, $blue);
    color: $white;

    .content-narrow, .content-wide {
        padding-top: 0;
        position: relative;
        z-index: 2;
    }
    //Two column layout
    .row {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 1000px;

        form {
            margin-top: 0;
        }
    }

    .sf_colsIn {
        padding: 0;
    }
}

//Banner spacing

.HeaderColumn2, .banner-placeholder .content-narrow, .header-image-2-col {
    padding: 6em 1.25em 3em;

    @include media-breakpoint-up(lg) { 
        padding: 11em 0 5em;
    }
} 
 


//Body spacing
.body-placeholder {
    overflow: hidden;

    section {
        margin-top: 3em;
        position: relative;

        @include media-breakpoint-down(md) {
            padding: 0 1em;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 100px;
        }
    }
    //inner padding top and bottom for backgrounds
    .background-spacing {

        @include media-breakpoint-down(md) {
            padding: 2.5em 1em;
        }

        @include media-breakpoint-up(lg) {
            padding: 100px 0;
        }
    }

    .mb-5 {
        margin-bottom: 100px !important;
    }
}




.content-narrow, .content-wide {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}
//Content narrow width
.content-narrow {
    @include media-breakpoint-up(lg) {
        max-width: 704px;
    }
}
//Content wide width
.content-wide {
    @include media-breakpoint-up(lg) {
        max-width: 1000px;
    }
}




// Full width max width for bootstrap columns
.row.wide {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 15px;
        align-items: initial;
    }
}

//Light blue background
.bg-light-blue {
    background-color: #a7bcc6;
}

/* overlay on headings*/
.BackBlock, .BackBlockWhite {
    background: rgba(0, 0, 0, 0.5);
    padding: 1.5em;
    border-radius: 10px;

    h1 {
        margin-top: 0 !important;
    }
}

.BackBlockWhite {
    background: rgba(255, 255, 255, 0.3);
}

//Remove the margin top if there is a layout widget inside of background layout widget
.bg-silver-light {
    section {
        margin-top: 0;
    }
}


svg{
    position: relative;
      &:after{
        display: block;
        content: "";
        width: 50px;
        height: 50px;
      }
}

//Whatsapp icon
@media (max-width:590px) {
    .eapp-whatsapp-chat-root-layout-component svg {
        width: 20px !important;
        height: 20px !important;
    }

    .Bubble__BubbleComponent-sc-83hmjh-3 {
        width: 40px !important;
        height: 40px !important;
    }
}