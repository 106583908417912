header {

    svg {
        fill: $gold;
        width: 25px;
        height: 25px;
    }

    .social-mobile {
        margin: 2em 0;

        svg {
            margin-right: 2.5em;
        }

        a {
            &:last-child {
                svg {
                    margin-right: 0;
                }
            }
        }
    }

    .LogoSvg{
        svg{
            height: 51px;
            width: 100%;
        }
    }
}

.popover-header {
    margin-top: 0;
}

.contact-header {
    svg {
        @include media-breakpoint-down(md) {
            fill: $gold;
        }
    }
    //1231 without home link
    .tab-content > .tab-pane {
        @media (min-width: 1200px) {
            display: block;
        }
    }

    .tab-content > .tab-pane#phone {
        @media (min-width: 1101px) {
            display: block;
        }
    }
    //Hide active tab content on mobile device when clicking the active tab again
    &.hide-content {
        .tab-content {
            display: none;
        }

        #tablist {
            a {
                &.active {
                    background: $silver-darkest;
                    color: $silver;

                    svg {
                        fill: $silver;
                    }
                }
            }
        }
    }

    .tab-content {
        width: 100%;
        padding-top: 0;

        @include media-breakpoint-up(md) {
            background: transparent;
            margin-top: 0;
            padding-top: 0.8em;
        }

        .tab-pane {
            padding: 1em;
            width: 100%;

            @include media-breakpoint-up(lg) {
                padding: 0;
                width: auto;
            }

            &.active {
                background: #ccc;
                padding: 2em;
                padding-bottom: 0;

                @include media-breakpoint-up(lg) {
                    background: transparent;
                    padding: 0;
                }
            }
        }
    }

    #tablist {
        justify-content: space-evenly;
        width: 100%;

        a {
            background: $silver-darkest;
            padding: .5em;
            text-align: center;
            width: 25%;
            cursor: pointer;
            color: $silver;
            margin-top: 1.2em;
            @include border-radius(0.25em);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            @include media-breakpoint-up(xl) {
                padding: 0;
                width: 100%;
                border-radius: none;
            }

            &.active {
                text-decoration: none;
                background: $silver;
                color: $silver-darkest;
                font-weight: bold;

                svg {
                    fill: $silver-darkest;
                }
            }

            &:first-child {
                margin-left: 2em;
            }

            &:last-child {
                margin-right: 2em;
            }
        }

        span {
            text-align: center;
            display: block;
        }
    }

    .tab-content {
        @include media-breakpoint-down(md) {
            span {
                color: $silver-darkest;
                font-size: 1em;
                letter-spacing: 0.511px;
                float: left;
                line-height: 32px;
                font-weight: bold;
                margin-left: 0.5em;
                margin-bottom: 1em;
            }
        }
    }
}

//Forex login and register buttons
.fx-account-links {
    width: 100%;
    background: $silver-darkest;
    display: flex; 

    @media (max-width: 450px) {
        height: auto;
        min-height: 65px;
    }

    > div {
        margin: 1.3em auto;

        @media (max-width: 450px) {
            width: 85%;
        }

        @media (min-width: 1101px) {
            margin: 0;
        }
    }

    @media (min-width: 1101px) {
        position: absolute;
        width: auto;
        background: transparent;
        z-index: 1000;
        bottom: -43px;
        right: 0;
    }

    .btn {
        margin-top: 0;
        padding: 4px 15px;
        color: white;
        font-family: "Jost-Light",arial,sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
        box-shadow: none;

        &:active, &:focus, &:hover {
            transform: none;
            box-shadow: none;
        }

        &.btn-small {
            padding: 3px 10px;
        }
    }
}

//Main Header
.navbar-brand {
    img {
        width: 100%;
    }
}


.getintouch, .search span, .portal-login {
    font-size: .9em;
    font-weight: 700;
    padding: 4px 15px;
    letter-spacing: 1.2px;    
}

.getintouch {
    padding-right: 0;
}

.getintouch {
    position: relative;
    background: #F4F4F4;
    padding: 5px 10px !important;
    @include border-radius(5px);
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.3);

    @include media-breakpoint-down(xl) {
        padding: 4px 0 4px 30px;
    }
    &:hover{
      text-decoration: none;
      background: #eaeaea;
      box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);
      -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.2);
        i{
            border-color: #684a10;       
        }
    }
    i {
        border: solid $gold;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }
}

/* Targettting the a wrapping the Get in touch button */
a.phone{
    &:hover{
       text-decoration: none !important;      
    }
}