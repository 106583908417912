/*Tables*/

table {
    width: 100%;

    td {
        vertical-align: top;
    }

    td, th {
        padding: .75rem;
        border: 1px solid #dee2e6 !important;
        vertical-align: middle !important;
    }

    tbody {
        background: $white;
    }

    th {
        background: #ececec;
    }
}

//Borderless
.table.table-borderless {
    td, th {
        border: 0 !important;
    }
}

//Comparison table
.comparison-table {

    td, th {
        text-align: center;
    }

    td {
        vertical-align: middle;

        &:first-child {
            text-align: right;
        }

        &:nth-child(even) {
            background-color: $silver-light;
        }
    }

    thead {
        border-bottom: 0.5em solid $blue-dark;
        border-top: 0.5em solid $blue;
        color: #fff;

        th {
            font-weight: normal;
            background: $blue;
        }
    }
}
