/*Lists*/

//Lists in blue box
.features-list {


    h3 {
        color: $white;
        padding: 0.5rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
        background: $blue;
        margin-top: 0;
    }

    .box {
        padding: 1em;
        border: 1px solid $silver;
        background: #fbfbfb;
    }

    ul, ol {
        margin-top: 1rem;

        li {
            margin-top: 0 !important;
            margin-bottom: 1.1em;
        }
    }

    ul {

        li {
            position: relative;
            padding-left: 25px;

            &:before {
                content: "\2713";
                position: absolute;
                display: inline-block;
                left: 0px;
                font-weight: bold;
            }
        }
    }
}
//Links list
.links-list {
    li {
        margin-top: 0 !important;

        a {
            background: $silver-lighter;
            border-radius: 0.45em;
            padding: 0.5em 1em;
            display: block;
            margin-top: 1em;
            position: relative;
            font-weight: bold;

            &:after {
                content: '\003E';
                position: absolute;
                right: 3%;
            }

            &:hover {
                background: $silver-dark;
                color: $white;
                text-decoration: none;
            }
        }
    }
}

.check-list, .cross-list, .hyphen-list {
    margin-left: 1.2em;

    li {
        position: relative;
        padding-left: 0.5em;

        &:before {
            content: "\2713";
            position: absolute;
            display: inline-block;
            left: -17px;
            font-weight: bold;
        }

        &:last-child {
            padding-bottom: 0;
        }

        ul {
            li {
                &:before {
                    content: "";
                }
            }
        }
    }
}

.cross-list {
    li {
        &:before {
            content: "\00d7";
        }
    }
}

.hyphen-list {

    li {
        &:before {
            content: "\2010";
            font-size: 24px;
            top: -7px;
        }
    }
}
//list of options
.options-list {
    counter-reset: number-counter;

    h4 {
        font-size: 1.4em;
        position: relative;

        &:before {
            content: counter(number-counter) ". ";
            counter-increment: number-counter;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 1.2em;
        }
    }

    h5, p {
        margin-top: 20px;
    }
     
    h5 {
        font-size: 1.2em;
        margin-top: 2em;
    }

    h4, h5, p {
        padding-left: 35px;
    }

    ul {
        padding-left: 75px;
    }
}
