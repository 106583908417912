//Imports  Sable and Bootstraps mixins,variables and functions
@import "../helpers/_helpers-dir.scss";

//Cards background
.pricing-cards .card, .nav-cards .card, .team-slider .card, .currency-converter {
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.3);
    @include border-radius(8px);
    padding: 0 0 25px 0;
    background: $white;
    text-align: center;
    height: 100%;

    @include media-breakpoint-up(md) {
        position: relative;
    }
}

.pricing-cards, .nav-cards {
    &.no-shadow {
        .card {
            box-shadow: none;
        }
    }
}

/*Nav Cards*/
.nav-cards {
    margin: 0 auto;
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
        margin-top: 32px;
    }
    // 3 or 6 cards add class to Navigation widget
    &.column-three {
        @include media-breakpoint-down(md) {
            max-width: 768px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 850px;
        }
    }
    // 4 cards add class to Navigation widget
    &.column-four {
        @include media-breakpoint-up(md) {
            max-width: 1120px;
        }
    }

    .col {
        flex-grow: 0;
    }

    .card {
        width: 290px;
        background: $silver-light;

        @include media-breakpoint-up(md) {
            width: 225px;
        }

        @include media-breakpoint-up(lg) {
            width: 250px;
        }

        a {
            @include pos-absolute;
            z-index: 2;
        }

        &:hover {
            box-shadow: none;

            .arrow {
                svg {
                    fill: $primary !important;
                }
            }
        }

        .header {
            padding: 10px;
            line-height: 24px;
            color: $blue;
            @include border-radius(8px 8px 0 0);

            @include media-breakpoint-up(lg) {
                min-height: 156px;
                padding: 18px 10px;
            }

            svg {
                margin-bottom: 1em;
                width: 50px;
                height: 50px;
            }

            svg, p {
                display: block;
            }

            p {
                width: 100%;
                margin-top: 0;
                font-size: .95rem;
                font-variation-settings: "wght" 650;
                color: $blue;

                @media (min-width: 600px) and (max-width: 1080px) {
                    min-height: 50px;
                }
            }
        }

        .content {
            @include media-breakpoint-up(md) {
                min-height: 100px;
            }

            p {
                margin: 0;
                padding: 10px;
                display: block;
                font-size: 0.85em;
                line-height: 1.40rem;
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                    color: $blue;
                }
            }
        }

        .BottomSection {
            padding: 10px 15px;
            border-radius: 0 0 8px 8px;
            background: #0c3b53;
            color: #fff;

            svg {
                fill: #fff !important;
                height: 28px !important;
                width: 28px !important;
            }

            p {
                font-variation-settings: "wght" 500;
            }
        }
    }


    &.tertiary-pages {
        // 2 cards (if the side navigation is in the way)
        &.column-two {
            @include media-breakpoint-up(md) {
                max-width: 800px;
            }
        }
        // 3 cards next to each other
        &.column-three {
            @include media-breakpoint-up(md) {
                max-width: 1024px;
            }
        }
        // 4 cards add class to Navigation widget
        &.column-four {
            @include media-breakpoint-up(md) {
                max-width: 1400px;
            }
        }

        img {
            position: absolute;
            height: 60%;
        }
        //Country ouline (add class to widget in Sitefinity)
        &.au-visa-outline {
            background-size: contain;
        }

        &.sa-visa-outline {
            background: url("/images/default-source/immigration/saoutline.jpg") center no-repeat;
            background-size: contain;
        }

        &.uk-visa-outline {
            background-size: contain;
        }

        &.earth-outline {
            background-size: contain;

            img {
                height: 52%;
            }
        }

        &.au-visa-outline, &.sa-visa-outline, &.uk-visa-outline, &.earth-outline {
            .card {
                background-color: rgba(232,232,232,.4);
            }
        }

        .header {
            color: $black;
            padding: 10px 0;
            margin: 0 10px;

            @include media-breakpoint-up(md) {
                border-bottom: 1px solid $gold;
                min-height: 81px;
            }

            p {
                @include media-breakpoint-up(md) {
                    padding-right: 4px;
                }
            }

            .arrow {
                margin-left: 5px;
            }
        }

        .card {
            width: 250px;
            text-align: left;
            padding-bottom: 0;

            @media only screen and (max-width: 399px) and (min-width: 350px) {
                width: 300px;
            }

            @media only screen and (max-width: 500px) and (min-width: 400px) {
                width: 335px;
            }

            @include media-breakpoint-up(md) {
                width: 295px;
                padding: 0 0 10px;
            }
        }

        .arrow {
            svg {
                width: 20px;
                height: 20px;
                margin-bottom: 0;
            }
        }

        .content {
            @include media-breakpoint-up(md) {
                min-height: 50px;
            }

            p {
                padding-bottom: 0;
                color: $silver-darkest;
            }
        }

        &.no-content {
            .header {
                min-height: 45px;
                border-bottom: none;
            }

            .card {
                padding: 0;

                @include media-breakpoint-up(md) {
                    width: 220px;
                }

                p {
                    font-size: 0.9rem;
                }
            }

            &.svg-icon {
                max-width: 1000px;

                .card {
                    @include media-breakpoint-up(md) {
                        width: 338px;
                    }

                    margin-right: 2em;
                }

                .icon {
                    border-radius: 50%;
                    background: $primary;
                    width: 50px !important;
                    height: 50px !important;
                    position: absolute;
                    left: 7px;

                    @include media-breakpoint-up(md) {
                        width: 60px !important;
                        height: 60px !important;
                        left: -13px;
                        top: -11px;
                    }

                    svg {
                        fill: #fff;
                        color: #fff;
                        width: 30px;
                        height: 30px;
                        margin: 9px auto 0;

                        @include media-breakpoint-up(md) {
                            width: 33px;
                            height: 33px;
                            margin: 12px auto 0;
                        }
                    }
                }
            }
            // 3 cards add class to Navigation widget
            &.column-three {
                @include media-breakpoint-up(md) {
                    max-width: 830px;
                }
            }
            // 5 cards add class to Navigation widget
            &.column-five {
                @include media-breakpoint-up(md) {
                    max-width: 1100px;
                }
            }
            // 5 cards add class to Navigation widget
            &.column-seven {
                @include media-breakpoint-up(md) {
                    max-width: 1500px;
                }
            }
        }
    }

    &.no-hover {
        .card {
            &:hover {
                @include box-shadow(0px, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
            }
        }
    }

    //Image nav cards, hard coded on the Offshore property page for now
    &.picture-cards{
        .card{
            @include media-breakpoint-up(lg) {
                max-height: 275px;
                width: 300px;
            }
            .BottomSection{
                min-height: 65px;
                @media only screen and (max-width: 600px) {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }
            }
            @media only screen and (max-width: 600px) {
                height: 165px;
                   img{
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    left: 0;
                    height: 144px;                   
                   }
            }            
        }
    }

}

//Visa sections with maps
.UKVisaMap{
    img{
        max-height: 300px;
        left: 25%;

        @include media-breakpoint-up(md) {
            max-height: 360px;
            left: 40%;
            top: -35px;     
        }
        @media screen and (min-width: 1470px) {
            left: 44%;            
        }
    }
    .card{
        background: rgba(255,255,255,0);
    }
    &.AUSVisaMap{
        img{
            left: 10%;
    
            @include media-breakpoint-up(md) {
                max-height: 436px;
                left: 30%;
                top: -16px;
            }  
            @media screen and (min-width: 1470px) {
                left: 38%;               
            }
        }
    }
}

    .country-background-card {
        position: relative;

        @include media-breakpoint-up(md) {
            height: 757px;
            margin-top: -3em;
            top: 0;

            img {
                position: absolute;
                height: 60%;
            }
        }

        &.EU {

            h2 {
                @include media-breakpoint-up(md) {
                    padding-top: 7em;
                }
            }
        }

        &.US {

            h2 {
                @include media-breakpoint-up(md) {
                    padding-top: 8em;
                }
            }
        }

        &.AU {

            .flag-icon {
                z-index: 5;
                position: relative;
            }

            img {
                margin-left: 0;
            }

            .heading {
                @include media-breakpoint-up(md) {
                    padding-top: 9em;
                }
            }

            &.AUVisas {
                @include media-breakpoint-up(md) {
                    height: 520px;
                }

                @include media-breakpoint-up(lg) {
                    height: 380px;
                }

                img {
                    margin-left: 0;
                    margin-top: -50px;
                    width: auto;
                    height: auto;

                    @include media-breakpoint-up(lg) {
                        height: 143%;
                        margin-top: -220px;
                        z-index: -1;
                    }
                }
            }
        }


        h2 {
            color: #157;
            text-align: center;
            z-index: 5;
            position: relative;
        }

        img {
            @include media-breakpoint-up(md) {
                height: inherit !important;
                margin-top: -19em;
                text-align: center;
                //margin-left: -19em;
            }
        }

        .flag-icon {
            border-radius: 50%;
            background: rgb(255 255 255);
            width: 60px !important;
            height: 60px !important;

            svg {
                width: 60px !important;
                height: 60px !important;
            }
        }

        .nav-cards {
            justify-content: center;

            @include media-breakpoint-up(md) {
                justify-content: space-between;
            }

            .flag-icon {
                position: absolute;
                left: -13px;
                top: -11px;

                svg {
                    margin: 0 auto;
                }
            }


            &.no-content {
                &.column-three {
                    max-width: 687px;
                }

                &.column-four {
                    max-width: 1200px;
                    padding: 0;
                    margin: 0 auto;
                }

                .card {
                    @include media-breakpoint-up(md) {
                        width: 295px;
                    }
                }
            }
        }
    }
    //background white for cards on a background
    .bg-silver-light {
        .nav-cards {
            &.tertiary-pages {
                .card {
                    background: white;
                }
            }
        }
    }
    /*Pricing Cards*/
    .pricing-cards {

        > div {
            margin-bottom: 1em;
            max-width: 400px;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        &.no-shadow {
            .card {
                border: 1px solid #ccc;
                border-top-left-radius: 1px;
                border-top-right-radius: 1px;
            }
        }

        .heading {
            padding: 1em 0;
            background: #eee;

            p {
                padding: 0 1em;
                margin-top: .5em;
            }
        }

        h3 {
            @include border-radius(8px 8px 0 0);
            color: #000;
            font-size: 1.35rem;
            margin-top: 0;
        }

        hr {
            width: 50%;
            border-color: $primary;
            margin-top: 0;
        }

        .price {
            background: #0C384F;
            color: #fff;
            padding: 1em 0;

            pricecheck {
                font-size: 2em;
            }
        }

        .details {
            padding: 2em 0;

            @include media-breakpoint-up(lg) {
                font-size: .87rem;
            }



            ul {
                margin: 0;
                padding: 0;
                padding: 0 2.5rem;
                text-align: left;
                margin-bottom: 1em;
                text-align: center;
                list-style: none;

                @include media-breakpoint-up(lg) {
                    margin: 0 auto 5.5em;
                }

                li {
                    margin-top: 0;
                    padding: .7em 0;
                    border-bottom: 1px solid $silver;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        .choose {
            .btn {
                padding: 13px 42px;
            }

            @include media-breakpoint-down(sm) {
                .btn {
                    margin-top: 20px;
                }
            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 0;
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 2em;
            }
        }
    }
    //IE Nav Cards
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .nav-cards {
            &.row {
                display: block;
                overflow: auto;
            }

            .col {
                width: auto;
                float: left;
            }
        }

        .column-three.nav-cards {
            max-width: 851px;
        }
    }
    // Firefox nav cards
    @-moz-document url-prefix() {
        .nav-cards .card .header p {
            font-size: 0.93rem;
        }

        #master-nav > li.active > .nav-link, #master-nav > li:hover > .nav-link {
            font-weight: 400;
        }
    }
