/*Accordion*/
.accordion-section {
    border-bottom: 1px solid #fff;
    background: $silver_light;
    position: relative;
    cursor: pointer;

    &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        &:before {
            border-top-left-radius: 10px;
        }
    }

    &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &:before {
            border-bottom-left-radius: 10px;
        }
    }

    &:before {
        content: "";
        background: $silver;
        width: 10px;
        height: 100%;
        position: absolute;
    }

    h3 {
        font-size: 1rem;
        margin: 0;
        padding-left: 0.5em;
        line-height: inherit;
        cursor: pointer;
    }

    p {
        &:first-child {
            margin-top: 0;
        }
    }

    .accordion-content, h3 {
        padding: 10px 10px 10px 18px;
    }

    .accordion-content {
        display: none;
        padding-top: 0;
    }

    .icon {
        width: 20px;
        height: 20px;
        background: $blue;
        float: right;
        margin: 11px 1.1em 0 1.1em;
        display: flex;
        justify-content: center;

        svg {
            position: relative;
            width: 11px;
            height: 11px;
            margin-top: -11px;
            top: 15.1px;
            left: 0.9px;
            fill: $silver-light;
            transform: rotate(90deg);
        }
    }

    &.active, &:hover {
        &:before {
            background: $blue;
        }
    }

    &.active {
        h3 {
            font-weight: bold;
        }
        .icon {
            background: $silver-dark;

            svg {
                transform: rotate(-90deg);
            }
        }
    }
}
//Dark accordion
.accordion {

     h3{
        font-variation-settings: "wght" 350;
    }
    &.dark {
        color: $white;

        .accordion-section {
            background: #707070;
            border-bottom: 1px solid $silver-darker;

            &:before {
                background: #7A7A7A;
            }

            &.active, &:hover {
                &:before {
                    background: #404040;
                }
            }
        }

        .icon {
            background: transparent;

            svg {
                fill: $white;
                width: 14px;
                height: 14px;
            }
        }
    }
}
