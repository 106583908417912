/*Shimmer*/

.shine {
    background: #eee;
    background-image: linear-gradient(to right, #eee 0%, #b7b7b7 20%, #eee 40%, #eee 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
}

box {
    margin-top: 15px;

    &.short {
        height: 74px;
    }

    &.tall {
        height: 92px;
    }
}


@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.shimmer-div {
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
}

lines {
    height: 10px;
    margin-top: 10px;
    width: 252px;
}

//Shimmer for CC
.CurrencyToolkit {

    box.currency {
        height: 92px;
    }

    box.input {
     
        margin-top: 26px;
        margin-left: 25px;
    }

    box.button {
        display: block !important;
        height: 71px;
        margin-top: 15px;
    } 
}

.reviews {
    .shimmer-div {
        &:nth-child(even) {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &:nth-child(odd) {
            @include media-breakpoint-up(md) {
                display: none;
            }

            @include media-breakpoint-up(lg) {
                display: inline-flex;
            }
        }
    }
}

.shimmer-form-bg-color {
    background: #f8f8f8;
}

.shimmer-line {
    height: 10px;
    margin-top: 10px;
    background: #777;
}

.shimmer-line-rounded {
    border-radius: 8px;
}

.shimmer-line-w-100 {
    width: 100%;
}

.shimmer-line-w-80 {
    width: 80%;
}

.shimmer-line-w-60 {
    width: 60%;
}

.shimmer-line-w-50 {
    width: 50%;
}

.shimmer-line-w-40 {
    width: 40%;
}

.shimmer-line-w-30 {
    width: 30%;
}

.shimmer-line-w-20 {
    width: 20%;
}

.shimmer-line-w-10 {
    width: 10%;
}

.shimmer-line-h-100 {
    height: 100px;
}
.shimmer-line-h-200 {
    height: 200px;
}
.shimmer-line-h-300 {
    height: 300px;
}
.shimmer-line-h-400 {
    height: 400px;
}

.shimmer-line-h-80 {
    height: 80px;
}

.shimmer-line-h-60 {
    height: 60px;
}

.shimmer-line-h-50 {
    height: 50px;
}

.shimmer-line-h-40 {
    height: 40px;
}

.shimmer-line-h-30 {
    height: 30px;
}

.shimmer-line-h-20 {
    height: 20px;
}

.shimmer-line-h-10 {
    height: 10px;
}

.shimmer-circle {
    background: #777;
    border-radius: 50%;
}

.shimmer-circle-lg {
    height: 129px;
    width: 129px;
}

.shimmer-circle-md {
    height: 65px;
    width: 65px;
}

.shimmer-circle-sm {
    height: 33px;
    width: 33px;
}

.shimmer-circle-x-sm {
    height: 17px;
    width: 17px;
}


.shimmer-wrapper {
    width: 100%;
    height: 100%;
    animation: shimmer-full-view 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

@keyframes shimmer-full-view {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

@keyframes shimmer-animation {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

.shimmer-animate {
    animation: shimmer-animation 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}