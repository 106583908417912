/*Core*/

body{
    margin: 0;
    font-size: 0.94rem;
    font-family: MontserratVF, Montserrat-fallback, sans-serif;
    font-variation-settings: "wght" 500;
    letter-spacing: .4px;
      @include media-breakpoint-up(md) {
            letter-spacing: .2px;            
        } 
}


.row {
    margin: 0;
}

.container {
    padding: 0;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    fill: $blue;
    color: $blue;
    width: 43px;
    height: 43px;
}

.small svg {
    width: 25px;
    height: 25px;
}

//We don't have bootstrap 5 yet so I'm adding this, to position elements right
.end-0{
    right: 0;
}

//Olark Chat messenger
.olark-required-asterisk {
    svg {
        height: inherit;
    }
}
.olark-chat-send-button {
    svg {
        height: initial;
    }
}

//hover links
a, .nav-link {
    &:hover {
        @include media-breakpoint-up(lg) {
            text-decoration: underline;
            -webkit-text-underline-position: under;
            -ms-text-underline-position: below;
            text-underline-position: under;
        }

    }
}

.arrow-chevron {
    border: solid #333;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;

    &.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {  
    h1, h2, h3, h4, h5, h6, strong, b, .btn, .nav-cards .card .header, .team-description .name, .custom-image-banner .services a, .nav-cards .card .BottomSection p, .blogFeedWrapper .BlogTextWrapper h3{
        font-family: "Montserrat-SemiBold";
    }    
    body, .banner-placeholder h4{    
        font-family: "Montserrat-Regular";
        font-weight: 400;
    }
}

.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}