/*Progress indicator*/

.progress {
  border-bottom: 1px solid $silver;
  background: transparent;
}

.progress-bar {
  background-color: $blue;
  border-radius: 0.25rem;
}