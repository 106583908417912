/*Pagination*/

.page-item {
  &:first-child,&:last-child {
    .page-link {
      background: $blue;
      border-color: $blue;
      color: $white;
      width: auto;
    }
  }
  &.active {
    .page-link {
      background-color: $silver-dark;
      border-color: $silver-dark;
 
    }
  }
  .page-link {
    border-radius: 0.25rem;
    margin-right: 0.5em;
    color: $black;
    width: 3em;
    background-color: $silver;
    border-color: $silver;
    text-align: center;
  }
}
