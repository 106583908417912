/*Header Banner Image*/

//Imports  Sable and Bootstraps mixins,variables and functions
@import "../helpers/_helpers-dir.scss";

.custom-image-banner {
    position: relative;
    padding-bottom: 25px;
    min-height: 100px;
    padding: 54px 15px 48px 15px;
    color: #fff;

    @include media-breakpoint-up(xl) {
        padding: 101px 15px 104px 15px;
    }

    .custom-banner-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            height: 100%;
            width: auto;
            position: absolute;
        }
    }

    .content-narrow {
        position: relative;
        padding: 0;
    }

    h1 {
        margin-top: 48px;

        @include media-breakpoint-up(md) {
            margin-top: 64px;
        }
    }

    h4 {
        font-size: 1rem;
        font-variation-settings: "wght" 200;

        @include media-breakpoint-up(md) {
            font-size: 1.146rem;
            line-height: 2rem;
            letter-spacing: .3px;
        }
    }

    h1, h4 {
        z-index: 20;
        position: relative;
    }

    .BackBlock {
        margin-top: 3em;
    }


    &.left-banner {
        .custom-banner-image {
            img {
                left: calc(50% - 705px);
                height: 100%;
                bottom: 0;

                @media only screen and (max-width: 699px) {
                    display: none;
                }

                @media only screen and (min-width: 700px) {
                    display: block;
                }

                @media only screen and (max-width: 1200px) and (min-width: 700px) {
                    left: calc(37% - 900px);
                }

                @media only screen and (max-width: 1400px) and (min-width: 1201px) {
                    left: calc(49% - 900px);
                }

                @media only screen and (min-width: 1401px) {
                    left: calc(51% - 900px);
                }

                @media only screen and (min-width: 1580px) {
                    left: calc(47% - 900px);
                }
            }

            &.Small {
                img {
                    @media only screen and (min-width: 700px) {
                        left: calc(80% - 900px);
                    }

                    @media only screen and (min-width: 1100px) {
                        left: calc(76% - 900px);
                    }

                    @media only screen and (min-width: 1200px) {
                        left: calc(68% - 900px);
                    }

                    @media only screen and (min-width: 1300px) {
                        left: 0;
                    }
                }
            }

            &.Medium {
                img {
                    @media only screen and (min-width: 700px) {
                        left: calc(70% - 900px);
                    }

                    @media only screen and (min-width: 1100px) {
                        left: calc(65% - 900px);
                    }

                    @media only screen and (min-width: 1200px) {
                        left: calc(55% - 900px);
                    }

                    @media only screen and (min-width: 1300px) {
                        left: calc(57% - 900px);
                    }

                    @media only screen and (min-width: 1564px) {
                        left: 0;
                    }
                }
            }

            .btn {
                position: relative;
            }

            &.DarkOpacity {
                &:before {
                    content: "";
                    display: block;
                    background: #000;
                    filter: alpha(opacity=15);
                    opacity: .15;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }

        .HeaderColumn2 {
            max-width: 850px !important;

            img {
                @media only screen and (min-width: 1401px) {
                    left: calc(55% - 900px);
                }

                @media only screen and (min-width: 1698px) {
                    left: 0;
                }
            }
        }
    }
    //Ends



    &.right-banner {
        .custom-banner-image {
            overflow: hidden;

            img {
                right: calc(50% - 951px);
                height: 100%;
                bottom: 0;

                @include media-breakpoint-down(md) {
                    display: none;
                }

                @media only screen and (max-width: 1200px) and (min-width: 700px) {
                    right: calc(66% - 902px);
                }
                /* @media only screen and (max-width: 1400px) and (min-width: 1201px) {
                    right: calc(65% - 902px);
                }*/

                @media only screen and (min-width: 1401px) {
                    right: calc(51% - 902px);
                }

                @media only screen and (min-width: 1580px) {
                    right: calc(47% - 902px);
                }
            }



            .btn {
                position: relative;
            }
        }

        .content-wide {
              max-width: 725px;
       //     max-width: 1140px;
       //     padding-right: 27em;
         

            @include media-breakpoint-up(md) {
                padding-right: 2em;
            }
        }
    }


    .ZIndex {
        z-index: 2;

        h1, h3, h4 {
            text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.50);
        }
    }

    .btn {
        position: relative;
    }
}

@media screen and (min-width:0) and (min-resolution:.001dpcm) {
    @media only screen and (min-width: 1600px) {
        .left-banner.custom-image-banner .custom-banner-image img.LongImage {
            height: 652px \9;
        }
    }
}




/* Firefox font fix renders banner h4 differently */
@-moz-document url-prefix() {
    .banner-placeholder {
        h4 {
            font-variation-settings: "wght" 450;
        }
    }
}
