/*Modal*/
.modal-header {
    border-bottom: none;

    .close {
        background: none;
        border: 1px solid $silver;
        border-radius: 50%;
        position: relative;
        margin: 0rem 0rem -1rem auto;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(53, 111, 139, 0.5);
        }

        &:hover {
            background: $silver-light;
        }

        svg {
            width: 14px;
            fill: $blue;
            justify-content: center;
            top: -5px;
            left: 9px;
            position: absolute;
        }
    }
}

.modal-footer {
    background: $silver-light;
    justify-content: center;
}

.modal-body {
    text-align: center;
}

.modal-title {
    margin-top: 0;
}

.alert {
    .close {
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        top: 10px;
        right: 7px;
        padding: 0;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(53, 111, 139, 0.5);
        }

        &:hover {
            background: $silver-light;
        }
    }
}

//Pop up notification
.modal-notification {
    &.pop-up {
        .modal-dialog {
            max-width: 100%;
            margin: 0;
        }

        .modal-content {
            background-color: $blue;

            .modal-header {

                p, a {
                    color: $white;
                }

                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .close {
                    background: $silver-light;
                }
            }
        }
    }
}


notification.noticeWrapper {
    position: fixed;
    width: 100%;
    bottom: -70px;
    padding: 2em;
       @media (max-width: 450px){
         padding: 0.6em;     
         font-size: 0.9rem !important;   
      }    
}

notification.noticeWrapper.display {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
    -webkit-transition: all, 0.45s ease;
    -moz-transition: all, 0.45s ease;
    -o-transition: all, 0.45s ease;
    transition: all, 0.45s ease;
    text-align: center;

    .close {
        display: inline-block;
        cursor: pointer;
        margin-left: 1em;
        top: -42px;
        position: absolute;
        background: inherit;
        border-radius: 1em;
        padding: 1em;
        box-shadow: 0 -5px 5px -5px #333; 


        @media (max-width: 1199px) {
            left: 0;
        }

        @media (min-width: 1200px) { 
            right: 109px;
        }

        @media (min-width: 1655px) {
            right: 310px;
        }

        svg {
            width: 22px;
            height: 22px;
        }
    }

    &:nth-of-type(1) {
        bottom: 0px;
        z-index: 99;
    }

    &:nth-of-type(2) {
        bottom: 20px;
        z-index: 98;
    }

    &:nth-of-type(3) {
        bottom: 40px;
        z-index: 97;
    }

    &:nth-of-type(4) {
        bottom: 60px;
        z-index: 96;
    }

    &:nth-of-type(2):hover {
        bottom: 80px;


        @include media-breakpoint-down(sm) {
            bottom: 157px;
        }
    }

    &:nth-of-type(3):hover {
        bottom: 103px;
    }

    &:nth-of-type(2):focus {
        bottom: 80px;
    }
}

notification.display[style*="background-color:"] {
    color: #fff;

    a {
        color: #fff;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    svg {
        fill: #fff;
        color: #fff;
    }
}

notification.display[style*="background-color:#fff"], notification.display[style*="background-color: rgb(255, 255, 255)"],
notification.display[style*="background-color:#eee"], notification.display[style*="background-color: rgb(238, 238, 238)"] {
    color: #000 !important;

    .close {
        color: $blue;

        svg {
            fill: $blue !important;
            color: $blue !important;
        }
    }

    a {
        color: $primary;
    }

    a.btn {
        color: #fff !important;
    }
}


//Cookies GDPR (3rd party)
.cookiefirst-root {
    .cf3E9g {
        font-size: .75rem;
        padding: 10px;
        margin: 1em;

        @include media-breakpoint-up(md) {
            font-size: .85rem;
            margin: 0;
        }
    }

    .cf3qKS {
        margin: 10px 1em;

        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }

    .cf1QhF, .cf3Tgk {
        margin-top: 7px;
    }
}