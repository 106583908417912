/*Features*/

//Imports Sable and Bootstraps mixins,variables and functions
@import "../helpers/_helpers-dir.scss";

//Documents
.features-document {
    .icon {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            background: $blue-darkest;
            border-top: 0.5em solid $blue;
            padding: 0 0.5em;
        }

        svg {
            @include media-breakpoint-up(lg) {
                fill: $white;
                width: 30px;
                margin-top: 1em;
            }
        }
    }

    .details {
        width: 100%;
        background: $silver_lighter;
        border: 1px solid $silver_light;
        margin: 0;

        .heading {
            justify-content: space-between;
            padding: 1em;
            align-items: center;
            background: $blue-darkest;
            border-top: 0.5em solid $blue;

            @include media-breakpoint-up(lg) {
                background: $silver_light;
                border-top: none;
                padding: 0.5em;
            }
        }

        h3 {
            font-weight: 400;
            margin: 0 !important;
            text-align: left;
            font-size: 1.2em;
            color: $white;

            @include media-breakpoint-up(lg) {
                color: $black;
            }
        }

        .btn {
            margin-top: 0;
        }

        p {
            padding: 0.5em;
            margin-top: 0;

            @include media-breakpoint-up(lg) {
                padding: 1em;
            }
        }
    }
}
//Features
.features-services {

    a.link-absolute {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    p {
        padding-left: 1em;
        line-height: 1.6em;
        margin-top: 10px;
        font-weight: bold;

        @include media-breakpoint-up(lg) {
            width: 80%;
        }
    }

    h5 {
        font-weight: bold;
    }

    .details {
        margin-left: 1em;
        width: 88%;

        p {
            font-weight: normal;
            padding-left: 0;
            width: 100%;
        }

        p.read-more {
            margin-top: 0.5em;
            display: block;
            font-weight: bold;
            color: $primary; 
        }
    }

    &.no-svg {

        .details {
            margin-left: 1em;
        }

        p {
            margin-top: 1em;
        }
    }

    .col-lg-6, .col-lg-4 {
        padding-left: 0;

        @include media-breakpoint-up(lg) {
            padding-right: 25px;
        }
    }

    svg {
        display: block;
    }
}

.features-services, .how-it-works {
    h5 {
        margin-top: 0;
        font-size: 1rem;
        font-weight:700;
       // color: $blue;
    }
}


.how-it-works {
    .col-xl-4 {
        padding-left: 0;
    }

    .row {
        > div {
            @include media-breakpoint-up(xl) {
                padding: 0;
            }
        }
    }

    svg {
        @media (max-width: 901px) {
            float: left;
        }
    }

    .details {
        float: right;
        width: 84%;
        padding: 0 0 2em 1em;

        @include media-breakpoint-up(xl) {
            float: none;
            margin: 1em;
            margin-bottom: 0;
            padding: 1em 0 0;
            border-top: 0.50px solid $blue;
            position: relative;
            width: 100%;

            &:before {
                content: '';
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: #157;
                position: absolute;
                top: -9px;
                left: -3px;
            }
        }

        p {
            margin-top: 20px;

            @include media-breakpoint-up(xl) {
                width: 86%;
            }
        }
    }


    &.collapsed {

        .details {

            @include media-breakpoint-up(xl) {
                border-left: 0.50px solid $blue;
                border-top: 0;
                padding: 0 0 2em 1em;
                margin: 0 1em;

                &:before {
                    top: 0;
                    left: -7px;
                }
            }
        }

        .row {
            &:last-child {
                .details {
                    padding-bottom: 0;
                }
            }
        }
    } 
}



// 3 steps rounded circle numbers
.how-it-works-simple {

    .col-md-4 {
        margin-bottom: 3em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .number {
        background: $blue-darkest;
        width: 70px;
        height: 70px;
        color: #fff;
        margin: 0 auto;
        font-size: 2.2em;
        font-weight: bold;

        @include media-breakpoint-up(md) {
            width: 90px;
            height: 90px;
        }
    }

    &.collapse-row { 
        margin-top: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }

        .row {
            margin-bottom: 22px;
        } 

        .number {
            width: 40px;
            height: 40px;
            font-size: 1.3em;
            margin-left: 0;
            margin-right: 10px;

            @include media-breakpoint-up(md) {
                width: 50px;
                height: 50px;
            }
        }

        h5 {
            margin-top: 0;
        }

        p {
            margin-top: 10px; 
        } 


    }
}

.bg-blue {
    .how-it-works-simple {
        .number {
            background: #fff;
            color: #157;
        }
    }
}


//category
.features-category {

    .category {
        border: 1px solid $silver-light;
        border-radius: 3px 3px 0 0;
        height: 100%;
    }

    h3 {
        background: $blue;
        border-radius: 3px 3px 0 0;
        margin-bottom: 0;
        padding: 10px;
        font-size: 16px;
        color: $white;
        margin-top: 0;
    }

    ul {
        padding: .5em;

        li {
            border-bottom: 1px solid $silver-light;
            padding: .7em;
            margin-top: 0;

            &:last-child {
                border: none;
            }
        }
    }
}
